<template>
  <div>
    <TopNav v-bind:showLogin=false v-bind:showStores=true />
    <TopNav v-bind:showLogin=false v-bind:showStores=true />
    <SidebarNav/>
    <div v-if="!fetched || sendingOrder || creatingAddress || !activeStore">
      <div class="loader-container-full-page">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <div class="row">
        <div class="col-12 col-md-9 mt-md-5 mb-3">
          <h1 class="mt-4">Delivery details</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12">
          <SamAccordion
              title="Choose delivery location"
              accordionId="samAccordianDeliveryLocation"
              default-open
          >
            <div class="row">
              <div class="col-md-3">
                <div>Delivery location*</div>
                <v-select
                    class="sam-select"
                    v-model="selectedaddr"
                    label="name"
                    :options="addresses"
                    :reduce="a => a.id"
                    :searchable="true"
                    :clearable="false"
                    placeholder="Select address"
                    @input="selectAddress()"
                ></v-select>
                <div v-if="$v.selectedaddr.$error">
                  <div class="form-field-error" v-if="!$v.selectedaddr.required">Delivery address is required</div>
                </div>
                <div class="mt-3">Postal code*</div>
                <input type="text" class="inputfield" v-model.trim="address.zip_code" :disabled="disableAddress"
                       @input="handleAddressChange"/>
                <div v-if="$v.address.zip_code.$error">
                  <div class="form-field-error" v-if="!$v.address.zip_code.required">Postal code is required</div>
                  <div class="form-field-error"
                       v-if="!$v.address.zip_code.maxlength && $v.address.zip_code.required">
                    Max
                    length 12 characters
                  </div>
                </div>
                <div class="mt-3">Delivery recipient*</div>
                <v-select
                    class="sam-select"
                    v-model="recipient.id"
                    label="name"
                    :options="recipientList"
                    :reduce="r => r.id"
                    :searchable="true"
                    :clearable="false"
                    placeholder="Select recipient"
                ></v-select>
                <div class="mt-3">Attachment</div>
                <label for="attachment" class="custom-file-upload mt-0 mb-0">
                  <i class="fa fa-cloud-upload"></i> Choose file
                </label>
                <input class="inputfile" type="file" id="attachment" ref="attachment"
                       v-on:change="handleFileUpload()"/>
                <div class="text-break" v-if="attachment && attachment.name">{{ attachment.name }}<span
                    class="remove-x float-right mt-1 pointer" @click="removeAttachment">X</span></div>
              </div>
              <div class="col-md-3">
                <div>Location name*</div>
                <input type="text" class="inputfield" v-model.trim="address.name" :disabled="disableAddress"
                       @input="handleAddressChange"/>
                <div v-if="$v.address.name.$error">
                  <div class="form-field-error" v-if="!$v.address.name.required">Address name is required</div>
                </div>
                <div class="mt-3">City*</div>
                <input type="text" class="inputfield" v-model.trim="address.city" :disabled="disableAddress"
                       @input="handleAddressChange"/>
                <div v-if="$v.address.city.$error">
                  <div class="form-field-error" v-if="!$v.address.city.required">City is required</div>
                </div>
                <div class="mt-3">Recipient name*</div>
                <input type="text" class="inputfield" v-model.trim="recipient.name"/>
                <div v-if="$v.recipient.name.$error">
                  <div class="form-field-error" v-if="!$v.recipient.name.required">Please fill recipient name</div>
                </div>
                <div class="mt-3">Cost center/PO number*</div>
                <v-select
                    class="sam-select"
                    v-model="selectedcc"
                    label="name"
                    :options="costcenters"
                    :reduce="cc => cc.id"
                    :searchable="true"
                    placeholder="Select cost center"
                    @input="selectCC()"
                ></v-select>
                <div v-if="$v.selectedcc.$error">
                  <div class="form-field-error" v-if="!$v.selectedcc.required">Cost center is required</div>
                </div>
              </div>
              <div class="col-md-3 mt-md-0 mt-3">
                <div>Street address*</div>
                <input type="text" class="inputfield" v-model.trim="address.address" :disabled="disableAddress"
                       @input="handleAddressChange"/>
                <div v-if="$v.address.address.$error">
                  <div class="form-field-error" v-if="!$v.address.address.required">Street address is required</div>
                  <div class="form-field-error" v-if="!$v.address.address.maxLength">Max length 45 characters</div>
                </div>
                <div class="mt-3">Country*</div>
                <v-select
                    class="sam-select"
                    label="name"
                    v-model="address.country.code"
                    :options="countries"
                    :reduce="s => s.value"
                    :clearable="false"
                    placeholder="Select country"
                    :disabled="disableAddress"
                    @input="handleBlur"
                ></v-select>
                <div v-if="$v.address.country.code.$error">
                  <div class="form-field-error" v-if="!$v.address.country.code.required">Country is required</div>
                </div>
                <div class="mt-3">Recipient phone number*</div>
                <input type="tel" pattern="[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
                       v-model.trim="recipient.phone"
                       class="inputfield"/>
                <div v-if="$v.recipient.phone.$error">
                  <div class="form-field-error" v-if="!$v.recipient.phone.required">Recipient phone number is
                    required
                  </div>
                  <div class="form-field-error" v-if="!$v.recipient.phone.phoneFormat">Phone number format seems
                    invalid
                  </div>
                </div>
                <div class="mt-3">Rebate</div>
                <input type="text" class="inputfield" v-model.trim="rebate" />
              </div>
              <div class="col-md-3">
                <div>Delivery notes</div>
                <div class="mb-2">
                  <textarea class="textarea" v-model="info" rows=4 v-on:change="updateInfo($event)"></textarea>
                  <div v-if="$v.info.$error">
                    <div class="form-field-error" v-if="!$v.info.maxlength">
                      Max length 80 characters
                    </div>
                  </div>
                </div>
                <div class="mt-4">Recipient email*</div>
                <input type="text" class="inputfield" v-model.trim="recipient.email"/>
                <div v-if="$v.recipient.email.$error">
                  <div class="form-field-error" v-if="!$v.recipient.email.required">Recipient email is required
                  </div>
                  <div class="form-field-error" v-else-if="!$v.recipient.email.email">Email format seems invalid
                  </div>
                  <div class="form-field-error" v-else-if="!$v.recipient.email.validChars">å, ä, ö are not allowed
                    characters
                  </div>
                </div>
                <div v-if="recipient.id == 'newRecipient'" class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" v-model="saveRecipient" id="saveReciCheck">
                  <label class="custom-control-label mt-3 mb-0" for="saveReciCheck">Save new recipient</label>
                </div>
                <div v-if="showSendRecipient" class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" v-model="sendToRecipient" id="sendConf">
                  <label class="custom-control-label mt-3 mb-0" for="sendConf">Send order confirmation to
                    recipient</label>
                </div>
              </div>
            </div>
          </SamAccordion>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12">
          <SamAccordion
              title="Select shipping"
              accordionId="samAccordianShipping"
              default-open
          >
            <div v-if="!activeCarriers || !validCarrierOrder || !activeRates">
              <div class="row">
                <div class="col-md-8">
                  Request your admin(s) to activate carriers and/or add product dimensions to see shipping info
                  and to receive tracking codes.
                </div>
              </div>
            </div>
            <div v-else-if="!addressSelected">
              <div class="row">
                <div class="col-md-8">
                  Please add or double-check your delivery details including street address, postal code,
                  city and country to see your shipping options.
                </div>
              </div>
            </div>
            <div v-else>
              <div v-if="!ratesFetched">
                <div class="row justify-content-center">
                  <div class="col-md-4 d-flex justify-content-center">
                    <div class="mini-loader">Loading...</div>
                  </div>
                </div>
              </div>
              <div v-for="(data, key, index) in shipComputed" :key="key">
                <h4 v-show="ratesFetched" v-if="index !== 0">And</h4>
                <ShippingSelector v-show="ratesFetched" :address="shippingAddress" :parcelData="data"
                                  :supplierId="key"
                                  @selected-rate="handleSelectedRate" @api-finished="handleApiFinished"
                                  @reset-rates-fetched="resetRatesFetched"/>
              </div>

            </div>
          </SamAccordion>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12">
          <SamAccordion
              title="Order summary"
              accordionId="samAccordianOrderSummary"
              default-open
          >
            <OrderSummary/>
          </SamAccordion>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-3">
          <button class="btn btn-lg btn-primary btn-block mt-2 mt-md-5" v-on:click="confirm">Confirm order</button>
        </div>
        <div class="col-12 col-md-3">
          <router-link :to="{ name: 'cart'}">
            <button class="btn btn-lg btn-outline-primary btn-block mt-2 mt-md-5">Edit cart</button>
          </router-link>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import TopNav from '@/modules/general/components/TopNav.vue'
import Footer from '@/modules/general/components/Footer.vue'
import ShippingSelector from '../components/ShippingSelector.vue'
import OrderSummary from '../components/OrderSummary'
import router from '@/router'
import {required, email, maxLength} from 'vuelidate/lib/validators'
import SidebarNav from "@/modules/general/components/sidebar/SidebarNav.vue";
import SamAccordion from "@/components/SamAccordion.vue";


export default {
  name: 'DeliveryDetails',
  components: {
    SamAccordion,
    SidebarNav,
    TopNav,
    Footer,
    ShippingSelector,
    OrderSummary,
  },
  data() {
    return {
      cartObjectsMain: [],
      storeSuppliersMain: [],
      mobileView: 768,
      tabletView: 1044,
      windowWidth: document.documentElement.clientWidth,
      selectedcc: this.$store.getters.orderDetails.cc ? this.$store.getters.orderDetails.cc : null,
      selectedaddr: this.$store.getters.orderDetails.address ? this.$store.getters.orderDetails.address : null,
      address: {
        address: null,
        address_extra: null,
        city: null,
        country: {},
        groups: [],
        notes: null,
        recipients: [],
        recipient_name: "--",
        store: null,
        zip_code: null,
      },
      newAddress: {
        name: "New",
        address: null,
        address_extra: null,
        city: null,
        country: {},
        groups: [],
        notes: null,
        recipients: [],
        recipient_name: "--",
        store: null,
        zip_code: null,
        id: "newAddress"
      },
      creatingAddress: false,
      disableAddress: false,
      info: this.$store.getters.orderDetails.info,
      sendingOrder: false,
      attachment: null,
      default_shipping_costs: "We will optimise shipping and confirm shipping costs before delivery unless agreed otherwise.",
      recipient: {
        name: null,
        phone: null,
        email: null,
        id: "newRecipient"
      },
      emptyRecipient: {
        name: "New",
        phone: null,
        email: null,
        id: "newRecipient"
      },
      saveAddress: false,
      saveRecipient: false,
      sendToRecipient: false,
      shippingAddress: {
        address: null,
        address_extra: null,
        city: null,
        country: {},
        zip_code: null,
      },
      sortedOrders: {},
      activeCarriers: false,
      validCarrierOrder: true,
      activeRates: true,
      addressSelected: false,
      ratesFetched: true,
      totalRateSelectors: 0,
      fetchedRates: [],
      selectedRates: [],
      rebate: null,
      shippingParams: {
        "to_address": {
          "name": "",
          "company": "ShippyPro",
          "street1": "",
          "street2": "",
          "city": "",
          "state": "",
          "zip": "",
          "country": "",
          "phone": "",
          "email": ""
        },
        "from_address": {
          "name": "",
          "company": "",
          "street1": "",
          "street2": "",
          "city": "",
          "state": "",
          "zip": "",
          "country": "",
          "phone": "",
          "email": ""
        },
        "parcels": [],
        "Insurance": 0,
        "InsuranceCurrency": "",//"EUR",
        "CashOnDelivery": 0,
        "CashOnDeliveryCurrency": "",//"EUR",
        "ContentDescription": "",
        "TotalValue": "",//"50.25 EUR",
        "ShippingService": "Standard"
      },
      shippingOrders: {},
      inValidCarrierOrder: false,
      postCodeMissing: false,
      postCodeMessage: null,
      serviceSelected: false,
    }
  },
  validations: {
    selectedaddr: {
      required
    },
    selectedcc: {
      required
    },
    info: {
        maxLength: maxLength(80)
    },
    address: {
      name: {
        required
      },
      address: {
        required,
        maxLength: maxLength(45)
      },
      zip_code: {
        required,
        maxLength: maxLength(12)
      },
      city: {
        required
      },
      country: {
        code: {
          required
        }
      }
    },
    recipient: {
      name: {
        required
      },
      phone: {
        required,
        phoneFormat(phone) {
          if (phone == "" || phone == null) {
            return true;
          }
          return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(phone)
        }
      },
      email: {
        email,
        required,
        validChars(email) {
          if (email == "" || email == null) {
            return true;
          }
          // Check email doesn't contain å, ä, ö
          return !['å', 'ä', 'ö'].some(e => email.includes(e));
        }
      }
    }
  },
  created() {
    if (!this.$store.state.activeStore || !this.$store.state.activeStore.id) {
      this.$store.dispatch('fetchStores');
    }
    this.$store.dispatch('fetchStoreCarriers');
  },
  mounted: async function () {
    if (this.cartObjectsMain.length === 0) {
      this.cartObjectsMain = this.$store.getters.cartProductObjects
    }
    this.selectedaddr = null

    const orderDetails = this.$store.getters.orderDetails;
    if (orderDetails) {
      if (orderDetails.recipient_id != 'newRecipient') {
        const r = this.recipientList.find(reci => reci.id == orderDetails.recipient_id)
        this.recipient = {...r};
      } else {
        this.recipient = {
          name: orderDetails.recipient_name,
          phone: orderDetails.phone,
          email: orderDetails.recipient_email,
          id: "newRecipient"
        }
      }
      if (orderDetails.save_recipient) {
        this.saveRecipient = orderDetails.save_recipient;
      }
    }
    if (this.$store.getters.isAdmin) {
      // Fetch groups if admin and not already fetched
      if (!this.$store.getters.groupsFetched) {
        this.$store.dispatch('fetchGroups');
      }
    }
    if (this.selectedaddr === undefined || this.selectedaddr === null) {
      this.selectedaddr = this.newAddress
    }
    if (!this.$store.getters.storeCarriersFetched) {
      try {
        await this.$store.dispatch('fetchStoreCarriers');
      } catch (e) {
        (e)
      }
      const storeCarriers = this.$store.getters.storeCarriers
      for (let carrier in storeCarriers) {
        if (carrier.status === 2) {
          this.activeCarriers = true;
          break;
        }
      }
    }
  },
  computed: {
    shipComputed() {
      return this.shippingOrders
    },
    countries() {
      if (!this.$store.getters['admin/countriesFetched']) {
        this.$store.dispatch('admin/fetchCountries')
      }
      const countries = this.$store.getters['admin/countries'].map(c => ({name: c[1], value: c[0]}))
      // remove duplicates
      return countries.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i);
    },
    costcenters() {
      if (this.$store.getters.costcenters.length < 1 && !this.$store.getters.ccsFetched) {
        this.$store.dispatch('fetchCostCenters');
        return []
      }
      if (this.$store.getters.isStaff) {
        // return all ccs to staff
        return this.$store.getters.costcenters;
      }
      if (!this.$store.getters.membership.costcenters) {
        return []
      }
      return this.$store.getters.costcenters.filter(cc => this.$store.getters.membership.costcenters.includes(cc.id));
    },
    addresses() {
      let address = [{...this.newAddress}]
      if (this.$store.getters.addresses.length < 1 && !this.$store.getters.addressesFetched) {
        this.$store.dispatch('fetchAddresses');
      }
      let addresses = this.$store.getters.addresses
      addresses = [...address, ...addresses]
      return addresses;
    },
    fetched() {
      return this.$store.state.cartProductObjectsFetched && this.memberFetched;
    },
    ccsFetched() {
      return this.$store.getters.ccsFetched
    },
    memberFetched() {
      return this.$store.state.membershipFetched;
    },
    addressesFetched() {
      return this.$store.state.addressesFetched;
    },
    store_shipping_message() {
      if (this.$store.getters.activeStore.shipping_message) {
        return this.$store.getters.activeStore.shipping_message.replace(/(?:\r\n|\r|\n)/g, '<br />');
      }
      return this.default_shipping_costs;
    },
    estimated_deliveries() {
      if (!this.$store.state.cartProductObjectsFetched) {
        this.$store.dispatch('fetchCartProductObjects');
      }
      return []
    },
    recipientList() {
      let recipients = [{...this.emptyRecipient}]
      const addr = this.addresses.find(a => a.id == this.selectedaddr)
      if (addr && addr.recipients) {
        recipients = [...recipients, ...addr.recipients]
      }
      return recipients;
    },
    recipientName() {
      return this.recipient.name;
    },
    recipientPhone() {
      return this.recipient.phone;
    },
    recipientEmail() {
      return this.recipient.email;
    },
    recipientId() {
      return this.recipient.id;
    },
    showSendRecipient() {
      if (this.recipientId != "newRecipient") {
        if (this.recipientEmail == null || !email(this.recipientEmail)) {
          return false;
        }
      }
      return true;
    },
    activeStore() {
      return this.$store.state.activeStore ? true : false;
    },
    cartObjects() {
      return this.$store.getters.cartProductObjects
    },
    storeSuppliers() {
      if (this.$store.state.activeStore) {
        if (!this.$store.getters['admin/suppliersFetched']) {
          this.$store.dispatch('admin/fetchSuppliers')
        }
        return this.$store.getters['admin/suppliers']
      } else {
        return []
      }
    }
  },
  watch: {
    '$store.getters.storeCarriers': {
      handler() {
        if (this.$store.getters.storeCarriers.length !== 0) {
          const storeCarriers = this.$store.getters.storeCarriers
          for (let i = 0; i < storeCarriers.length; i++) {
            if (storeCarriers[i].status === 2) {
              this.activeCarriers = true;
              break;
            }
          }
        }
      },
      immediate: true, // Trigger the watcher immediately when the component is created
    },
    recipientName: function (newVal) {
      this.address.recipient_name = newVal
      this.$store.commit('updateOrder', {key: "recipient_name", value: newVal});
    },
    recipientPhone: function (newVal) {
      let phone = newVal;
      if (newVal == "") {
        this.recipient.phone = null;
        phone = null;
      }
      this.$store.commit('updateOrder', {key: "phone", value: phone});
    },
    recipientEmail: function (newVal) {
      this.$store.commit('updateOrder', {key: "recipient_email", value: newVal});
    },
    recipientId: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.$store.commit('updateOrder', {key: "recipient_id", value: newVal});
        const r = this.recipientList.find(reci => reci.id == newVal)
        if (r) {
          this.recipient = {...r};

          if (r.id != "newRecipient") {
            this.saveRecipient = false;
          } else {
            this.recipient.name = "";
          }
        }
      }
    },
    saveRecipient: function (newVal) {
      this.$store.commit('updateOrder', {key: "save_recipient", value: newVal});
    },
    sendToRecipient: function (newVal) {
      this.$store.commit('updateOrder', {key: "send_to_recipient", value: newVal});
    },
    ccsFetched: function (newVal, oldVal) {
      if (newVal != oldVal) {
        if (!this.selectedcc) {
          //If no cc selected, try to set to default
          if (this.costcenters.find(cc => cc.id == this.$store.getters.membership.default_cc)) {
            this.selectedcc = this.$store.getters.membership.default_cc;
          }
        }
      }
    },
    memberFetched: function (newVal, oldVal) {
      if (newVal != oldVal) {
        if (!this.selectedcc && this.ccsFetched) {
          if (this.costcenters.find(cc => cc.id == this.$store.getters.membership.default_cc)) {
            this.selectedcc = this.$store.getters.membership.default_cc;
          }
        }
        if (!this.selectedaddr && this.addressesFetched) {
          if (this.$store.getters.addresses.find(a => a.id == this.$store.getters.membership.default_address)) {
            this.selectedaddr = this.$store.getters.membership.default_address;
            this.selectAddress();
          }
        }
        if (this.$store.getters.isAdmin) {
          // Fetch groups if admin and not already fetched
          if (!this.$store.getters.groupsFetched) {
            this.$store.dispatch('fetchGroups');
          }
        }
      }
    },
    addressesFetched: function (newVal, oldVal) {
      if (newVal != oldVal) {
        if (!this.selectedaddr) {
          if (this.$store.getters.addresses.find(a => a.id == this.$store.getters.membership.default_address)) {
            this.selectedaddr = this.$store.getters.membership.default_address;
            this.selectAddress();
          }
        }
      }
    },
    activeStore: function (newVal, oldVal) {
      if (newVal && !oldVal) {
        if (!this.$store.state.cartProductObjectsFetched) {
          this.$store.dispatch('fetchCartProductObjects');
        }
      }
    },
    cartObjects: function (newVal, oldVal) {
      if (oldVal.length === 0 && newVal.length > 0) {
        this.cartObjectsMain = newVal
      }
    },
    storeSuppliers: function (newVal, oldVal) {
      if (oldVal.length === 0 && newVal.length > 0) {
        this.storeSuppliersMain = newVal
      }
    },
  },
  methods: {
    async getSuppliers() {
      try {
        let resp = await this.$http({
          method: 'get',
          url: process.env.VUE_APP_API_URL + "/api/stores/" + this.$store.getters.activeStore.id + "/storesuppliers/"
        })
        this.storeSuppliersMain = resp.data
      } catch (error) {
        throw error
      }
    },
    handleSelectedRate(selectedRate, supplierId) {
      const index = this.selectedRates.findIndex(obj => obj.hasOwnProperty(supplierId));
      if (index !== -1) {
        this.serviceSelected = true
        this.selectedRates[index] = {[supplierId]: selectedRate};
        this.selectedRates = [...this.selectedRates];
        this.$store.commit("updateSelectedRates", this.selectedRates)
      }else{
        this.serviceSelected = false
      }
    }
    ,
    handleApiFinished(rates, msg) {
      const vm = this
      if(msg){
        vm.$toastr.e(msg);
        vm.postCodeMissing = true
        vm.postCodeMessage = msg
      }else{
        vm.postCodeMissing = false
        vm.postCodeMessage = null
      }
      this.fetchedRates.push(rates);
      if (this.fetchedRates.length === this.totalRateSelectors) {
        if (this.fetchedRates.includes(400)) {
          this.addressSelected = false
          this.shippingOrders = {}
          this.$store.commit("clearSelectedRates")
        } else {
          if (this.fetchedRates.includes(false)) {
            this.activeRates = false
            this.shippingOrders = {}
            this.$store.commit("clearSelectedRates")
          } else {
            this.activeRates = true
          }
        }
        this.ratesFetched = true;
        this.fetchedRates = [];
      }
    }
    ,
    resetRatesFetched() {
      this.ratesFetched = false;
    }
    ,
    updateName(event) {
      this.$store.commit('updateOrder', {key: "recipient_name", value: event.target.value.trim()});
    }
    ,
    async selectAddress() {
      this.$v.address.address.$reset()
      this.addressSelected = true
      this.disableAddress = this.selectedaddr !== "newAddress"
      this.$store.commit('updateOrder', {key: "address", value: this.selectedaddr});
      this.recipient = {...this.emptyRecipient, ...{name: ""}};
      this.selectedcc = null;
      this.selectCC();
      this.saveRecipient = false;
      const addr = this.addresses.find(a => a.id == this.selectedaddr)
      if (addr.id !== 'newAddress') {
        this.resetRatesFetched()
        this.address = {...addr};
        this.shippingAddress = this.address;
        this.$v.address.address.$touch();
        if (this.$v.address.address.$invalid) {
          this.addressSelected = false;
          return;
        }
        if (this.activeCarriers === true) {
          await this.createParcels()
        }
        this.addressSelected = false;
        if (Object.keys(this.shippingOrders).length !== 0) {
          this.addressSelected = true;
        }
        if (addr.notes) {
          this.info = addr.notes;
          this.$store.commit('updateOrder', {key: "info", value: addr.notes});
        } else {
          this.info = null;
          this.$store.commit('updateOrder', {key: "info", value: null});
        }


      } else {
        this.addressSelected = false;
        this.$store.commit("clearSelectedRates")
        this.address = {...this.newAddress}
        this.shippingOrders = {}
        this.$v.address.address.$reset();
      }
    }
    ,
    async handleBlur() {
      if (this.address.name && this.address.address && this.address.address.length<=45 && this.address.zip_code && this.address.city && this.address.country.code) {
        this.addressSelected = true;
        this.resetRatesFetched()
        this.shippingAddress = this.address;
        if (this.activeCarriers === true) {
          await this.createParcels()
        }
        if (Object.keys(this.shippingOrders).length !== 0) {
          this.addressSelected = true;
        }
        this.ratesFetched = true
      }
    }
    ,
    handleAddressChange() {
      //this.$v.address.address.$touch();
      this.address.country = {}
      this.addressSelected = false;
      this.fetchedRates = [];
      this.ratesFetched = false;
    }
    ,
    selectCC() {
      if (this.selectedcc === "") {
        this.selectedcc = null;
      }
      this.$store.commit('updateOrder', {key: "cc", value: this.selectedcc});
    },
    getCartItemCount(id) {
      const total = this.$store.getters.cartItems.reduce((acc, product) => {
      if (product.productId === id) {
        return acc + product.count;
      }
        return acc;
      }, 0);
      return { productId: id, count: total };
    },
    updateInfo(event) {
      this.$store.commit('updateOrder', {key: "info", value: event.target.value});
    },
    updatePhone(event) {
      this.$store.commit('updateOrder', {key: "phone", value: event.target.value.trim()});
    },
    async validateSupplierInfo(supplierInfo) {
      return !(!supplierInfo.phone ||
          !supplierInfo.address ||
          !supplierInfo.zip_code ||
          !supplierInfo.city ||
          !supplierInfo.country);
    },
    async createParcel(key, suppliersInfo) {
      return new Promise(async (resolve) => {
        let parcels = []
        const parcelParams = JSON.parse(JSON.stringify(this.shippingParams));
        let parcelLength = 0.0;
        let parcelWidth = 0.0;
        let parcelHeight = 0.0;
        let parcelWeight = 0.0;
        let parcelPrice = 0.0;
        let contentDescription = ''
        let productIds = []
        for (const product of this.sortedOrders[key]) {
          contentDescription += product.name + ", ";
          if (product.length === null || product.width === null || product.height === null || product.weight === null) {
            parcels = [];
            this.validCarrierOrder = false;
            this.inValidCarrierOrder = true;
            resolve(false)
          } else {
            this.inValidCarrierOrder = false;
            const cartProductItem = this.getCartItemCount(product.id)
            parcelLength += parseFloat(product.length) * cartProductItem.count;
            parcelWidth += parseFloat(product.width)
            parcelHeight += parseFloat(product.height)
            parcelWeight += parseFloat(product.weight) * cartProductItem.count;
            parcelPrice += parseFloat(product.price) * cartProductItem.count;
            productIds.push(cartProductItem.productId);
          }
        }
        parcels = [{
          length: parcelLength,
          width: parcelWidth,
          height: parcelHeight,
          weight: parcelWeight
        }];

        const supplierInfo = suppliersInfo.find(obj => obj.id === parseInt(key));
        const isValid = await this.validateSupplierInfo(supplierInfo);
        if (isValid === false) {
          this.inValidCarrierOrder = true;
          resolve(false)
        }
        let currency = this.$store.getters.currencyStr;
        contentDescription = contentDescription.slice(0, -2);

        parcelParams["from_address"] = {
          "name": supplierInfo.name,
          "company": supplierInfo.name,
          "street1": supplierInfo.address,
          "street2": "",
          "city": supplierInfo.city,
          "state": "",
          "zip": supplierInfo.zip_code,
          "country": supplierInfo.country,
          "phone": supplierInfo.phone,
          "email": supplierInfo.email
        }
        parcelParams["to_address"] = {
          "name": this.shippingAddress.name,
          "company": "",
          "street1": this.shippingAddress.address,
          "street2": this.shippingAddress.address_extra,
          "city": this.shippingAddress.city,
          "state": "",
          "zip": this.shippingAddress.zip_code,
          "country": this.shippingAddress.country.code,
          "phone": supplierInfo.phone,
          "email": supplierInfo.email
        }
        parcelParams["products"] = productIds;
        parcelParams["parcels"] = parcels;
        parcelParams["ContentDescription"] = contentDescription;
        parcelParams["TotalValue"] = String(parcelPrice) + currency;
        parcelParams["CashOnDeliveryCurrency"] = currency;
        parcelParams["InsuranceCurrency"] = currency;
        resolve(parcelParams)
      })
    },
    async createParcels() {
      this.sortedOrders = {}
      let cartProductsObjects = this.cartObjectsMain
      for (const obj of cartProductsObjects) {
        let supplier = '';
        let itemObject = '';
        if (!obj.stock_management) {
          supplier = obj.producer;
          itemObject = obj;
        } else {
          supplier = obj.warehouse;
          itemObject = obj;
        }
        const isKeyPresent = this.selectedRates.some(obj => Object.keys(obj)[0] === String(supplier));
        if (!isKeyPresent) {
          this.selectedRates.push({[supplier]: {}});
        }
        if (this.sortedOrders.hasOwnProperty(supplier)) {
          this.sortedOrders[supplier].push(itemObject);
        } else {
          this.sortedOrders[supplier] = [itemObject]
        }
      }
      try {
        await this.getSuppliers()
      } catch (e) {
        //console.log("e:", e)
      }
      const suppliersInfo = this.storeSuppliersMain
      if (suppliersInfo.length === 0) {
        this.validCarrierOrder = false
        return
      }
      for (const key in this.sortedOrders) {
        const params = await this.createParcel(key, suppliersInfo)
        if (params !== false) {
          this.shippingOrders[key] = []
          this.shippingOrders[key].push(params);
        } else {
          this.shippingOrders = {}
          break
        }
      }
      this.totalRateSelectors = Object.keys(this.sortedOrders).length;
      if (!this.selectedcc && this.$store.getters.ccsFetched) {
        //If no cc selected, try to set to default
        if (this.costcenters.find(cc => cc.id == this.$store.getters.membership.default_cc)) {
          this.selectedcc = this.$store.getters.membership.default_cc;
        }
      }
      if (!this.selectedaddr && this.$store.getters.addressesFetched) {
        if (this.$store.getters.addresses.find(a => a.id == this.$store.getters.membership.default_address)) {
          this.selectedaddr = this.$store.getters.membership.default_address;
          this.selectAddress();
        }
      }
    },
    createShipObject(obj, supplierID, data) {
      let shipObject = {}
      obj["to_address"]["name"] = data.recipient_name
      obj["to_address"]["phone"] = data.phone
      obj["to_address"]["email"] = data.recipient_email
      shipObject["to_address"] = obj["to_address"]
      shipObject["from_address"] = obj["from_address"]
      shipObject["parcels"] = obj["parcels"]
      shipObject["TotalValue"] = obj["TotalValue"]
      shipObject["TransactionID"] = "CHANGEVALUE TO ORDER ID"
      shipObject["ContentDescription"] = obj["ContentDescription"]
      shipObject["Insurance"] = obj["Insurance"]
      shipObject["InsuranceCurrency"] = obj["InsuranceCurrency"]
      shipObject["CashOnDelivery"] = obj["CashOnDelivery"]
      shipObject["CashOnDeliveryCurrency"] = obj["CashOnDeliveryCurrency"]
      shipObject["CashOnDeliveryType"] = 0
      shipObject["CarrierName"] = obj["selectedRate"]["carrier"]
      shipObject["CarrierService"] = obj["selectedRate"]["service"]
      shipObject["CarrierID"] = parseInt(obj["selectedRate"]["carrier_id"])
      shipObject["CarrierLabel"] = obj["selectedRate"]["carrier_label"]
      shipObject["OrderID"] = ""
      shipObject["RateID"] = obj["selectedRate"]["rate_id"]
      shipObject["Rate"] = obj["selectedRate"]["rate"]
      shipObject["Incoterm"] = "DDP"
      shipObject["BillAccountNumber"] = ""
      shipObject["Note"] = data.info
      shipObject["Async"] = false
      shipObject["Products"] = obj["products"]
      shipObject["ProductsInfo"] = data.products
      shipObject["Currency"] = this.$store.getters.currencyStr;
      shipObject["supplierID"] = supplierID
      return shipObject;
    }
    ,
    confirm() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.sendingOrder = false;
        return;
      }

      if(this.activeCarriers && this.inValidCarrierOrder) {
        this.$toastr.e("Please contact Framme staff to check the product(s) dimensions and supplier(s) information.")
        return
      }
      if(this.activeCarriers && this.postCodeMissing) {
        this.$toastr.e(this.postCodeMessage)
        return
      }
      if(this.activeCarriers && !this.serviceSelected) {
        this.$toastr.e("Please select a shipping service.")
        return
      }
      if (this.saveAddress) {
        this.createNewLocation(this.address)
      }

      this.sendingOrder = true;
      const store = this.$store.getters.activeStore;
      let data = this.$store.getters.orderDetails;
      const vm = this;
      let headers = {};
      data.products = this.$store.getters.cartItems;
      data.leased_products = this.$store.getters.cartLeaseItems;
      data.store = store.id;
      data["rebate"] = this.rebate;

      let selectedRates = this.selectedRates
      let shippingOrders = []
      for (let i = 0; i < selectedRates.length; i++) {
        const item = selectedRates[i];
        const supplierKey = Object.keys(item)[0];
        const firstKeyValue = item[supplierKey];
        if (Object.keys(firstKeyValue).length !== 0) {
          let shipObj = this.createShipObject(firstKeyValue, supplierKey, data)
          shippingOrders.push(shipObj)
        } else {
          shippingOrders = []
          break; // Exit the loop
        }
      }
      data["shipping_orders"] = shippingOrders
      if (data['address'] === null || data['address'] === 'newAddress') {
        data['new_address'] = this.address
      }
      if (this.attachment && this.attachment.name) {
        headers['Content-Type'] = 'multipart/form-data';
        data = this.JSONToFormData(data);
      } else {
        headers['Content-Type'] = 'application/json';
      }
      this.$http({
        url: process.env.VUE_APP_API_URL + "/api/stores/" + store.id + "/order/",
        method: 'POST',
        data: data,
        headers: headers
      })
          .then(response => {
            if (response.data.order_id) {
              vm.$store.commit('updateOrder', {key: "order_id", value: response.data.order_id});
              vm.$store.commit('updateOrder', {key: "requires_approval", value: response.data.requires_approval});
              vm.$store.commit('updateOrder', {key: "id", value: response.data.id});
            }
            // check attachment
            if (response.data.attachment) {
              this.$store.commit('updateOrder', {key: "attachment", value: response.data.attachment});
            }
            // refetch addresses to update recipients
            vm.$store.dispatch('fetchAddresses');
            // copy cart data to sentOrder tms and clear cart and orderDetails
            vm.$store.dispatch('copySentOrderAndClearCart');
            // clear products to get updated stock balances
            //vm.$store.dispatch('clearProducts');
            vm.$store.dispatch('clearAndFetchProducts')
            if (vm.$store.getters.storeFeatures.leased_products) {
              // clear lease products to get updated statuses
              vm.$store.dispatch('clearLeaseProducts');
            }
            // clear the fetched orders
            vm.$store.dispatch('clearOrders');

            // clear leaseorders fetched if lease products included
            if (data.leased_products) {
              vm.$store.dispatch('clearLeaseOrders');
            }

            router.push({name: 'orderconfirmation'});
            this.sendingOrder = false;
          })
          .catch(error => {
            this.sendingOrder = false;
            if (error.request) {
              vm.$toastr.e(
                  error.request.responseText
              );
            }
            vm.$toastr.e(
                error
            );
          })

    }
    ,
    handleFileUpload() {
      if (this.$refs.attachment.files.length == 0) {
        return;
      }
      if (this.$refs.attachment.files[0].size > 4000000) {
        this.toastr.error("File size must be under 4MB");
        return;
      }
      let file = this.$refs.attachment.files[0];
      if (file.name.length > 80) {
        // Make sure the filename is max 80char + extension
        let newFileName = file.name.substring(0, file.name.lastIndexOf(".")).substring(0, 80);
        const fileExt = file.name.substring(file.name.lastIndexOf(".") + 1)
        if (!file.name.lastIndexOf(".")) {
          // no dot in filename, just shorten the string
          newFileName = file.name.substring(0, 80);
        } else {
          // add file extension to name
          newFileName += "." + fileExt;
        }
        // replace the file with new one to update the name
        this.attachment = new File([file], newFileName, {
          type: file.type,
          lastModified: file.lastModified,
        });
      } else {
        this.attachment = file;
      }
    }
    ,
    JSONToFormData(data) {
      const formData = new FormData();
      formData.append('attachment', this.attachment);
      for (var key in data) {
        if (Array.isArray(data[key])) {
          formData.append(key, JSON.stringify(data[key]));
        } else if (typeof data[key] === 'object') {
          formData.set(key, JSON.stringify(data[key]));
        } else {
          formData.append(key, data[key]);
        }
      }
      return formData;
    },
    removeAttachment() {
      this.attachment = null;
    }
    ,
    createNewLocation(address) {
      this.creatingAddress = true;
      //this.closeCreateLocationModal();
      const vm = this;
      if (!address.store) {
        address['store'] = this.$store.getters.activeStore.id
      }
      let data = {...address};

      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.getters.activeStore.id + '/addresses/';
      const method = 'POST';
      data.store = this.$store.getters.activeStore.id;

      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
          .then(function (response) {
            // add address to VUEX
            vm.$store.dispatch('addAddress', response.data)
            vm.$toastr.s("New delivery address created.");
            document.body.style.cursor = 'default';
            setTimeout(function () {
              vm.selectedaddr = response.data.id;
              vm.selectAddress();
              vm.creatingAddress = false;
            }, 1500);
          })
          .catch(function (error) {
            if (error.request) {
              vm.$toastr.e(
                  error.request.responseText
              );
            }
            vm.$toastr.e(
                error
            );
            document.body.style.cursor = 'default';
            vm.creatingAddress = false;
          });
    }
  }
}
</script>
